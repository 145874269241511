import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type LoadMoreListProps = {
  list: React.ReactElement[],
  itemsPerLoad: number
};

function LoadMoreList({ list, itemsPerLoad }: LoadMoreListProps): React.ReactElement {
  const { i18n, t } = useTranslation();

  const [loadTimes, setLoadTimes] = useState(1);
  const [currentList, setCurrentList] = useState(list.slice(0, loadTimes * itemsPerLoad));
  const [showLoadMore, setShowLoadMore] = useState(true);

  useEffect(() => {
    setCurrentList(list.slice(0, loadTimes * itemsPerLoad));
    setShowLoadMore((loadTimes * itemsPerLoad) >= list.length);
  }, [loadTimes, i18n.language, list]);

  const loadMore = () => {
    setLoadTimes((prevState) => prevState + 1);
  };

  return (
    <>
      {currentList}
      {showLoadMore || (
        <div className="col-12 d-flex justify-content-center mt-3">
          <Button variant="primary" onClick={loadMore}>{t('Load more')}</Button>
        </div>
      )}
    </>
  );
}

export default LoadMoreList;
