import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { navigate } from 'gatsby';

import SearchProvider, { useSearch } from '@core/providers/search/search-provider';
import { isBrowser } from '@core/helpers/ssr';
import { getHomePath } from '@core/helpers/link';
import LoadMoreList from '@shared/load-more-list';
import Hero from '@shared/hero/hero';
import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import Card from '@shared/card/card';
import { Graphql } from '@models/graphql';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';

const { default: crumbs } = require('@config/breadcrumbs');
const { contentTypes } = require('@config/content-types');

type ContentType = {
  name: string;
  type: string;
};

type SearchPageProps = {
  language: string
};

function SearchPage({ language }: SearchPageProps): React.ReactElement {
  const { t } = useTranslation();
  const { handleTextSearch, contentTags } = useSearch();
  const [results, setResults] = useState([]);
  const [subtitle, setSubTitle] = useState(`${t('Search')}`);
  const [selectedDay, setSelectedDay] = useState<string|null>(null);
  const [contentType, setContentType] = useState('');
  const [contentTag, setContentTag] = useState('');

  const heroData = {
    title: `${t('Search title')}`,
    subtitle: '',
    margin: 'mb-5',
    relationships: {
      image: {
        name: 'hero-contact.jpg'
      }
    }
  } as Graphql;

  useEffect(() => {
    if (isBrowser) {
      const params = new URLSearchParams(window.location.search);
      const searchTerm = params.get('text');

      if (searchTerm) {
        setSubTitle(searchTerm);
        setResults(handleTextSearch(language, searchTerm));
      } else {
        const pathHome = getHomePath();
        if (pathHome) {
          navigate(pathHome);
        }
      }
    }
  }, [language]);

  const renderSearch = results.map((search: Graphql) => (
    <Col className="mb-4 col-6" key={search.id} lg={3}>
      <Card key={search.id} node={search} />
    </Col>
  ));

  const handleChangeText = (_text:string) => {
    setResults(handleTextSearch(language, _text, contentType, contentTag, selectedDay));
    setSubTitle(_text);
  };

  const handleContentType = (_contentType:string) => {
    setResults(handleTextSearch(language, subtitle, _contentType, contentTag, selectedDay));
    setContentType(_contentType);
  };

  const handleContentTag = (_contentTag:string) => {
    setResults(handleTextSearch(language, subtitle, contentType, _contentTag, selectedDay));
    setContentTag(_contentTag);
  };

  const handleCalendar = (_data:string) => {
    setResults(handleTextSearch(language, subtitle, contentType, contentTag, _data));
    setSelectedDay(_data);
  };

  return (
    <>
      <Hero node={{ ...heroData, subtitle }} />
      <Breadcrumb crumbs={crumbs.concat({ label: t('Search'), link: '#' })} />
      <Container className="my-4">
        <div className="row">
          <div className="col-4">
            <Form.Group controlId="SelectContentType">
              <Form.Label>{t('Content type')}</Form.Label>
              <Form.Control as="select" custom onChange={(event) => handleContentType(event.target.value)}>
                <option value="">{t('Select a Content Type')}</option>
                {contentTypes.map((option: ContentType) => (
                  <option
                    key={option.type}
                    value={option.type}
                  >
                    {t(option.name)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="SelectContentTag">
              <Form.Label>{t('Tags')}</Form.Label>
              <Form.Control as="select" custom onChange={(event) => handleContentTag(event.target.value)}>
                <option value="">{t('Select a Tag')}</option>
                {contentTags.map((option: Graphql) => (
                  <option
                    key={option?.node?.id}
                    value={option?.node?.id}
                  >
                    {option?.node?.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('Calendar')}</Form.Label>
                <input
                  type="date"
                  className="form-control bg-white cursor-pointer W-100"
                  onChange={(event) => handleCalendar(event.target.value.replace(/-/g, '\/'))}
                />
            </Form.Group>
          </div>
        </div>
        <>
          <Row>
            <Col className="mb-4 mt-4" lg={5} md={6} sm={12}>
              <input
                type="text"
                className="form-control search-input mb-3 focus"
                placeholder={`${t('Search')}...`}
                value={subtitle}
                onChange={(event) => handleChangeText(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            {results.length === 0 && (<Col>{t('Empty search')}</Col>)}
            <LoadMoreList list={renderSearch} itemsPerLoad={12} />
          </Row>
        </>
      </Container>
    </>
  );
}

export default function SearchPageWrapper({ language }: SearchPageProps) {
  return (
    <SearchProvider>
      <SearchPage language={language} />
    </SearchProvider>
  );
}
